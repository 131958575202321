<script>
import api from '@/command/api'
import DetailFormGroup from '@/components/DetailFormGroup'
import CardIconLabelLocal from '@/components/CardIconLabelLocal'
import apiTool from '@/command/apiTool'
import { getAction, postAction, putAction } from '@/command/netTool'
import { Cascader, DatePicker, message } from 'ant-design-vue'

import Commodity from '@/views/com/Commodity'
import moment from 'moment'
import {
  daysBeforeList,
  daysAfterList,
  timeAfterList,
  ticketRefundType,
  ticketRefundRatioList,
  hotelDaysAfterList,
  ticketRefundType1,
} from '@/utils/textFile'

export default {
  name: 'package-detail',
  data() {
    return {
      ...api.command.getState(),
      activeKey: 0,

      detail: {
        preSellFlag: '1',
        preSellContinuesDays: null,
        hxMode: '0',
        qrShowMode: '0',
        refundRate: '',
        startTime: '23',
        startMinute: '59',
        payType: '0',
        buyCount: 0,
        userInfoCount: 0,
        comboType: '0',
        realNameFlag: [],
        realNameInput: [],
        preSellStartDate: '',
        preSellEndDate: '',
        preSellHxExpireType: '5',
        details: [],
        preDay: 0,
        hxExpireType: '1',
        hxAfterDay: 0,
        hxStartTime: '',
        hxEndTime: '',
        refundExplain: '',
        changeTicket: '0',
        useAfterDay: 0,
        useAfterHour: 0,
        refundWay: '0',
        refundList: [
          // {
          //   validDay: '',
          //   validDate: '6',
          //   refundType: '0',
          //   refundExplain: 0,
          // },
        ],
      },
      // businessIdArr: [],
      productInfo: {},
      returnSet: [],
      startTimeDataArr: [],
      startMinuteDataArr: [],
      labelGroup: [],
    }
  },
  mounted() {
    document.querySelector('.detail-form-group').addEventListener('scroll', () => {
      document.querySelector('body').focus()
    })
    for (let index = 0; index < 24; index++) {
      this.startTimeDataArr.push({
        name: index,
        value: index,
      })
    }
    for (let index = 0; index < 60; index++) {
      this.startMinuteDataArr.push({
        name: index,
        value: index,
      })
    }
    const { id } = this.$route.query

    api.command.getLabelCodeGroup.call(this, {
      url: '/api/base/farmLabelData/getByCodeSelf',
      params: {
        id: id ?? '',
        codes: [
          {
            key: 'farm_combo',
          },
        ],
      },
    })
    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/goods/farmProductCombo/detail?id=${id}`,
        })
        .then((res) => {
          this.storeSearch(res.linkShopName)
          this.detail = {
            ...res,
            preSellHxExpireType: '5',
            flushTime: res.flushTime && res.flushTime + '',
            realNameFlag: !res.realNameFlag ? [] : ['0'].includes(res.realNameFlag) ? [] : res.realNameFlag.split(','),
            realNameInput: res.realNameInput ? res.realNameInput.split(',') : [],
            ticketGiveSetting: res.ticketGiveSetting ? res.ticketGiveSetting.split(',') : [],
            details: res.details.map((e, i) => {
              this.storeSearchFarmProductCombo(e).then((res) => {
                this.detail.details[i].productList = res || []
                this.$forceUpdate()
              })
              return {
                ...e,
              }
            }),
            refundList: res.refundList || [],
          }
          this.detail.preSellFlag = res.preSellFlag == '1' ? '0' : '1'

          this.$forceUpdate()
        })
    }
  },
  methods: {
    /** 数据查询 */
    async storeSearch(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`,
      })
      this.storeData = searchResult.map((el) => {
        return {
          ...el,
          name: el.mainBodyName,
          value: el.id,
        }
      })
    },
    async storeSearchFarmProductCombo(item) {
      if (item.businessType === '2') {
        return await api.command.search.call(this, {
          url: `/api/goods/farmProductCombo/getHotelProductList?businessId=${item.businessId}`,
        })
      } else {
        return await api.command.search.call(this, {
          url: `/api/goods/farmProductCombo/getTicketProductList?businessId=${item.businessId}`,
        })
      }
    },
    /** end 数据查询 */
    onPreSellTimeChange(times) {
      if (times.length > 0) {
        this.detail.preSellStartDate = moment(times[0]).format('YYYY-MM-DD')
        this.detail.preSellEndDate = moment(times[1]).format('YYYY-MM-DD')
      }
    },
    getPreSellData() {
      return [this.detail.preSellStartDate, this.detail.preSellEndDate]
    },
    onTimeChange(times) {
      if (times.length > 0) {
        this.detail.hxStartTime = moment(times[0]).format('YYYY-MM-DD 00:00:00')
        this.detail.hxEndTime = moment(times[1]).format('YYYY-MM-DD 00:00:00')
      }
    },
    getData() {
      return [this.detail.hxStartTime, this.detail.hxEndTime]
    },
    // #region 基础设置
    getForm1() {
      const { id } = this.$route.query
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '是否预售商品',
            type: 'switch',
            cols: 24,
            key: 'preSellFlag',
            disabled: id,
            onChange: (state) => {
              this.detail.preSellFlag = state ? '1' : '0'
              if (state) {
                this.detail.comboType = '0'
                this.detail.roomBuySetting = '1'
                this.detail.syncFlag = '0'
              }
            },
          },
          {
            name: '套餐名称（40字符）',
            type: 'input',
            cols: 12,
            key: 'comboName',
            props: {
              maxLength: 40,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '类型',
            type: 'select',
            cols: 12,
            key: 'comboType',
            typeData: [
              {
                name: '酒店+景点',
                value: '0',
                show: true,
              },
              {
                name: '景点+景点',
                value: '1',
                show: this.detail.preSellFlag === '1',
              },
            ].filter((e) => e.show),
            onChange: () => {
              this.detail.refundExplain = ''
            },
            rules: [
              {
                required: true,
                type: 'string',
                message: '请选择类型',
              },
            ],
          },
        ],
      }
    },
    getFormUp() {
      return {
        type: 'tabs',
        key: 'selectType',
        children: [
          {
            title: () => (
              <div>
                <span style="color:#f00">*</span>
                Banner（限制10张）
              </div>
            ),
            data: [
              {
                type: 'iconUpload',
                maxLength: 1,
                cols: 12,
                descTitle: 'Banner',
                typeData: [
                  {
                    maxLength: 10,
                    key: 'bannerUrl',
                    multiple: true,
                  },
                ],
                rules: [
                  {
                    required: true,
                    message: '请上传图片',
                    type: 'string',
                  },
                ],
              },
            ],
          },
          {
            title: '宣传视频',
            data: [
              {
                type: 'iconUploadVideo',
                cols: 12,
                descTitle: '宣传视频',
                typeData: [
                  {
                    maxLength: 1,
                    key: 'videoUrl',
                    desc: '视频上传尺寸<20M <br /> 支持格式.mov/.mp4/.wmv/.avi',
                  },
                ],
              },
            ],
          },
        ],
      }
    },
    renderTableTop() {
      /**
         * <a-button
            ghost
            type="danger"
            icon="delete"
            style={{ marginRight: '20px' }}
          >
            删除线路
          </a-button>
         */
      return (
        <div class="add_commodity">
          <div>添加商品</div>
          <a-dropdown>
            <a-button ghost type="primary" onClick={this.showCommodity}>
              添加商品
            </a-button>
          </a-dropdown>
        </div>
      )
    },
    getForm2() {
      let that = this
      return {
        type: 'table',
        showFootButton: false,
        header: this.renderTableTop,
        showRowSelect: false,
        showPagination: false,
        columns: [
          {
            dataIndex: 'mainBodyName',
            align: 'left',
            title: '店铺名称',
            width: '20%',
          },
          {
            dataIndex: 'businessName',
            align: 'left',
            title: '酒店/景点名称',
            width: '20%',
          },
          {
            dataIndex: 'productName',
            align: 'left',
            title: '商品名称',
            width: '25%',
            customRender: (text, records) => {
              const onChange = (data) => {
                records.salesJsAmount = data.salesJsAmount || 0
                records.productId = data.productId
                records.productName = data.productName
                records.calendarDate = data.calendarDate
                records.salePrice = data.salePrice
                records.salePriceTotal = data.salePrice * records.productQuantity
                records.playCount = data.playCount

                that.$forceUpdate()
              }
              const onChangeCascader = (value, data) => {
                records.productId = data[0].productId
                records.productName = data[0].productName
                records.calendarDate = data[1].calendarDate
                records.salePrice = data[1].salePrice
                records.salePriceTotal = data[1].salePrice * records.productQuantity
                records.hotelRoomName = data[1].hotelRoomName
                records.skuId = data[1].skuId
                that.$forceUpdate()
              }

              if (records.businessType === '2') {
                const options = (records.productList || []).map((e) => ({
                  ...e,
                  value: e.productId,
                  label: e.productName,
                  children: e.productList.map((li) => ({
                    ...li,
                    value: li.skuId,
                    label: li.hotelRoomName,
                  })),
                }))
                return (
                  <div class=" relative">
                    <Cascader
                      class="select"
                      allowClear={false}
                      value={records.skuId && [records.productId, records.skuId]}
                      options={options}
                      style={{ width: '100%' }}
                      placeholder="商品名称"
                      onChange={onChangeCascader}
                      getPopupContainer={() => {
                        {
                          /* return new Promise(resolve => {
                          this.$nextTick(() => {
                            console.log(
                              'detail-form-group',
                              document.querySelector('.view-box')
                            )
                            resolve(
                              document.querySelector('.detail-form-group')
                            )
                          })
                        }) */
                        }
                        console.log('detail-form-group', document.querySelector('.detail-form-group'))
                        return document.querySelector('.detail-form-group')
                      }}
                    />
                  </div>
                )
              }
              const productIdArr = this.detail.details.filter((e) => e.productId).map((e) => e.productId)
              return (
                <a-select
                  class="select"
                  value={records.productName}
                  onChange={onChange}
                  style={{ width: '100%' }}
                  placeholder="商品名称"
                  defaultValue={text}
                  getPopupContainer={() => document.querySelector('.detail-form-group')}
                >
                  {(records.productList || []).map((e, i) => {
                    return (
                      <a-select-option value={e} key={i} disabled={productIdArr.includes(e.productId)}>
                        {e.productName}
                      </a-select-option>
                    )
                  })}
                </a-select>
              )
            },
          },
          // {
          //   dataIndex: 'salePrice',
          //   align: 'left',
          //   title: '单价（元）',
          //   width: '10%',
          // },
          {
            dataIndex: 'productQuantity',
            align: 'left',
            title: '数量',
            width: '20%',
            customRender: function (text, records) {
              const onInput = (data) => {
                let value = data.target.value
                // if (value < 1) {
                //   value = 1
                // }

                // 数量大于库存
                // if (records.businessType === '1' && value > records.productCount) {
                //   that.$message.warning(`剩余最多商品库存为${records.productCount}`)
                //   value = records.productCount
                // }
                records.productQuantity = value
                const result = records.productQuantity * records.salePrice
                records.salePriceTotal = isNaN(result) ? 0 : result
                that.$forceUpdate()
              }
              records.productQuantity = records.productQuantity || 1
              return (
                <div>
                  <a-input style={{ width: '50%' }} type="number" placeholder="数量" onChange={onInput} value={text} />{' '}
                  {records.businessType == '2' ? '晚' : '张'}
                </div>
              )
            },
          },
          {
            dataIndex: 'playCount',
            align: 'left',
            title: '游玩次数',
            width: '10%',
          },
          {
            dataIndex: 'a10',
            align: 'center',
            width: 180,
            title: '操作',
            type: 'buttonGroup',
            typeData: function ({ text, records, index }) {
              return [
                {
                  name: '删除',
                  type: 'pop',
                  popTitle: '确认是否删除吗?',
                  onClick: () => {
                    that.detail.details.splice(index, 1)
                    that.$forceUpdate()
                  },
                },
              ]
            },
          },
        ],
        dataSource: this.detail.details,
      }
    },
    getForm3() {
      return {
        title: '',
        type: 'cardForm',
        cardStyle: 'margin-top: 0',
        data: [
          {
            display: this.detail.comboType == '0',
            name: '酒店是否可以预订多晚',
            type: 'radioButton',
            key: 'roomBuySetting',
            cols: 6,
            disabled: this.detail.preSellFlag === '0',

            typeData: [
              {
                name: '否',
                value: '0',
              },
              {
                name: '是',
                value: '1',
              },
            ],
            rules: [
              {
                required: true,
                message: '请选择酒店是否可以预订多晚',
              },
            ],
          },
          {
            display: true,
            name: '是否同步价格',
            type: 'radioButton',
            key: 'syncFlag',
            cols: 6,
            disabled: this.detail.preSellFlag === '0',

            typeData: [
              {
                name: '否',
                value: '0',
              },
              {
                name: '是',
                value: '1',
              },
            ],
            rules: [
              {
                required: true,
                message: '请选择是否同步价格',
              },
            ],
          },
          // {
          //   display: true,
          //   name: '门票配置',
          //   type: 'checkBoxButton',
          //   key: 'ticketGiveSetting',
          //   cols: 6,
          //   typeData: [
          //     {
          //       name: '送一次',
          //       value: '0',
          //     },
          //   ],
          //   // rules: [
          //   //   {
          //   //     required: true,
          //   //     message: '请选择门票配置',
          //   //   },
          //   // ],
          // },
        ].filter((d) => d.display),
      }
    },
    onAddRule(index) {
      this.returnSet.push({})
    },
    // #endregion 基础设置

    // 销售设置
    getFormXssz() {
      return {
        title: '销售设置',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '支付方式',
            type: 'radioButton',
            key: 'payType',
            cols: 12,
            typeData: [
              {
                name: '在线支付',
                value: '0',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            display: this.detail.preSellFlag === '1',
            name: '最大购买数量 (限制条件:用户ID   不输入0代表不限制)',
            type: 'inputNumber',
            key: 'buyCount',
            cols: 12,
            props: {
              min: 0,
              placeholder: '最大购买数量',
            },
            rules: [
              {
                required: true,
                type: 'number',
              },
            ],
          },
          {
            display: true,
            name: '最大可预定天数',
            type: 'row',
            cols: 12,
            gutter: 6,
            children: [
              {
                type: 'text',
                value: '在开售时间起提前',
                cols: 4,
              },
              {
                label: '',
                type: 'inputNumber',
                key: 'preDay',
                cols: 3,
                props: {
                  min: 0,
                },
              },
              {
                type: 'text',
                value: '天可提前预定',
                cols: 4,
              },
            ],
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.preSellFlag === '1',
            name: '使用日期(新的设置适用于新产生的订单；对于已经产生的订单，仍需按原有的核销时间为用户提供服务)',
            type: 'radio',
            key: 'hxExpireType',
            cols: 24,
            typeData: [
              {
                name: () => {
                  const onInput = (data) => {
                    this.detail.hxAfterDay = data.target.value
                  }
                  return (
                    <span style="margin-right: 100px">
                      必须在购买后&nbsp;
                      <a-input
                        style={{ width: '70px' }}
                        type="number"
                        min={0}
                        placeholder=""
                        onChange={onInput}
                        value={this.detail.hxAfterDay}
                      />
                      &nbsp;天内使用
                    </span>
                  )
                },
                value: '1',
              },
              {
                name: () => {
                  return <span style="margin-right: 100px">在指定购买日期使用</span>
                },
                value: '0',
              },
              {
                name: () => {
                  return (
                    <span>
                      在购买后&nbsp;
                      <DatePicker.RangePicker
                        showTime={false}
                        ranges={{
                          最近一周: [moment(), moment().add(1, 'weeks')],
                          最近一个月: [moment(), moment().add(1, 'month')],
                          最近三个月: [moment(), moment().add(3, 'month')],
                        }}
                        allowClear={false}
                        onChange={this.onTimeChange}
                        value={this.getData()}
                        style={{ width: '400px' }}
                        format="YYYY-MM-DD"
                        placeholder={['开始日期', '结束日期']}
                      ></DatePicker.RangePicker>
                      &nbsp;时间段内都可使用
                    </span>
                  )
                },
                value: '5',
              },
            ],
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '生效时间限制',
            type: 'row',
            cols: 12,
            gutter: 6,
            children: [
              {
                type: 'text',
                value: '必须在购买后',
                cols: 3,
              },
              {
                label: '',
                type: 'inputNumber',
                key: 'useAfterDay',
                cols: 3,
                props: {
                  min: 0,
                },
                rules: [
                  {
                    required: true,
                    type: 'number',
                  },
                ],
              },
              {
                type: 'text',
                value: '天，',
                cols: 1,
              },
              {
                label: '',
                type: 'inputNumber',
                key: 'useAfterHour',
                cols: 3,
                props: {
                  min: 0,
                  max: 23,
                },
                rules: [
                  {
                    required: true,
                    type: 'number',
                  },
                ],
              },
              {
                type: 'text',
                value: '小时，才可使用',
                cols: 4,
              },
            ],
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '用户信息',
            type: 'checkBoxButton',
            key: 'realNameFlag',
            cols: 12,
            typeData: [
              {
                name: '实名制',
                value: '1',
              },
            ],
          },
          {
            display: this.detail.realNameFlag?.length > 0,
            name: '下单时填写信息',
            type: 'checkBoxButton',
            key: 'realNameInput',
            cols: 12,
            typeData: [
              {
                name: '姓名',
                value: 'link_man',
              },
              {
                name: '电话',
                value: 'link_phone',
              },
              {
                name: '身份证',
                value: 'link_credit_no',
              },
            ],
            rules: [
              {
                required: true,
                type: 'array',
                message: '请最少选择一种',
              },
            ],
          },
          {
            display: true,
            name: '用户信息数量',
            type: 'radioButton',
            key: 'userInfoCount',
            cols: 12,
            typeData: [
              {
                name: '只填写一个人信息',
                value: 0,
              },
              {
                name: '根据下单数量填写所有用户信息',
                value: 1,
              },
            ],
            rules: [
              {
                required: true,
                type: 'number',
              },
            ],
          },
        ].filter((e) => e.display),
      }
    },
    // #end 销售设置
    getFormPreSellFlag() {
      const that = this
      return {
        title: '预售设置',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '使用日期(新的设置适用于新产生的订单；对于已经产生的订单，仍需按原有的核销时间为用户提供服务)',
            type: 'radio',
            key: 'preSellHxExpireType',
            cols: 12,
            typeData: [
              {
                name: () => {
                  return (
                    <span>
                      在&nbsp;
                      <DatePicker.RangePicker
                        showTime={false}
                        ranges={{
                          最近一周: [moment(), moment().add(1, 'weeks')],
                          最近一个月: [moment(), moment().add(1, 'month')],
                          最近三个月: [moment(), moment().add(3, 'month')],
                        }}
                        allowClear={false}
                        onChange={this.onPreSellTimeChange}
                        value={this.getPreSellData()}
                        style={{ width: '400px' }}
                        format="YYYY-MM-DD"
                        placeholder={['开始日期', '结束日期']}
                      ></DatePicker.RangePicker>
                      &nbsp;时间段内都可使用
                    </span>
                  )
                },
                value: '5',
              },
            ],
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '最大购买数量 (限制条件:用户ID   不输入0代表不限制)',
            type: 'inputNumber',
            key: 'buyCount',
            cols: 6,
            props: {
              min: 0,
              placeholder: '最大购买数量',
            },
            rules: [
              {
                required: true,
                type: 'number',
              },
            ],
          },
          {
            name: '酒店预售入住天数',
            type: 'inputNumber',
            cols: 6,
            key: 'preSellContinuesDays',
            props: {
              min: 1,
            },
            rules: [
              {
                required: true,
                type: 'number',
              },
            ],
          },
        ],
      }
    },
    getFormPreSellFlag2() {
      const that = this
      return {
        title: '退款设置',
        type: 'cardForm',
        data: [
          {
            name: '未预约退款规则',
            type: 'checkRadioButton',
            cols: 24,
            key: 'noAppointmentRefundType',
            typeData: [
              {
                name: '未预约，随时退',
                value: '0',
                type: 'check',
              },
              {
                name: '未预约，过期自动退',
                value: '2',
                type: 'check',
              },
              {
                name: '不可退',
                value: '1',
                type: 'radio',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            display: true,
            name: '预约成功退款规则',
            type: 'checkRadioButton',
            key: 'makeAppointmentRefundType',
            cols: 24,
            typeData: [
              {
                name: '订单预约后，随时退',
                value: '0',
                type: 'check',
              },
              {
                name: '订单预约后，过期自动退',
                value: '2',
                type: 'check',
              },
              {
                name: '不可退',
                value: '1',
                type: 'radio',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      }
    },
    getFormHotel() {
      const that = this
      return {
        title: '退款设置',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '退款设置',
            type: 'checkBoxButton',
            key: 'freeRefundFlag',
            cols: 24,
            typeData: [
              {
                name: '在购买当天订单确认后30分钟内免费退',
                value: '1',
              },
            ],
          },
          {
            display: true,
            name: '退款方式',
            type: 'radioButton',
            key: 'refundExplain',
            cols: 6,
            typeData: [
              {
                name: '支持订单确认后，入住前可退款',
                value: '2',
              },
              {
                name: '不可退',
                value: '1',
              },
              // {
              //   name: '过期不可退',
              //   value: '2'
              // },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            display: this.detail.refundExplain === '1',
            name: '不可退款协议',
            type: 'input',
            cols: 9,
            key: 'refundAgreement',
          },
          {
            display: that.detail.refundExplain == '2',
            cols: 24,
            type: 'table',
            showFootButton: true,
            showRowSelect: false,
            showPagination: false,
            // table新增按钮回调
            onAddData: () => {
              this.$forceUpdate()
              return {
                validDay: '0',
                validDate: '6',
                refundType: '0',
                refundExplain: 0,
              }
            },
            columns: [
              {
                dataIndex: 'validDay',
                align: 'left',
                width: '22%',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>入住有效日期
                  </div>
                ),
                customRender: function (text, records, index) {
                  const onChange = (data) => {
                    records.validDay = data
                  }
                  return (
                    <a-select
                      onChange={onChange}
                      style={{ width: '100%' }}
                      placeholder="有效日期"
                      defaultValue={text}
                      getPopupContainer={() => document.querySelector('.detail-form-group')}
                    >
                      {hotelDaysAfterList.map((e) => {
                        return <a-select-option value={e.value}>{e.name}</a-select-option>
                      })}
                    </a-select>
                  )
                },
              },
              {
                dataIndex: 'validDate',
                align: 'left',
                width: '22%',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>入住有效时间前
                  </div>
                ),
                customRender: function (text, records, index) {
                  const onChange = (data) => {
                    records.validDate = data
                  }
                  return (
                    <a-select
                      onChange={onChange}
                      style={{ width: '100%' }}
                      placeholder="有效时间"
                      defaultValue={text}
                      getPopupContainer={() => document.querySelector('.detail-form-group')}
                    >
                      {timeAfterList.map((e) => {
                        return <a-select-option value={e.value}>{e.name}</a-select-option>
                      })}
                    </a-select>
                  )
                },
              },
              {
                dataIndex: 'refundType',
                align: 'left',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>退款类别
                  </div>
                ),
                width: '22%',
                customRender: function (text, records, index) {
                  const onChange = (data) => {
                    records.refundType = data
                    records.refundExplain = 0

                    that.$forceUpdate()
                  }
                  return (
                    <a-select
                      onChange={onChange}
                      style={{ width: '100%' }}
                      placeholder="退款类别"
                      defaultValue={text}
                      getPopupContainer={() => document.querySelector('.detail-form-group')}
                    >
                      {ticketRefundType1.map((e) => {
                        return <a-select-option value={e.value}>{e.name}</a-select-option>
                      })}
                    </a-select>
                  )
                },
              },
              {
                dataIndex: 'refundExplain',
                align: 'left',
                width: '22%',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>扣款金额/系数
                  </div>
                ),
                customRender: function (text, records, index) {
                  const onInput = (data) => {
                    data = Number(data.target.value)
                    if (data < 0) {
                      data = 0
                    }

                    data = data.toFixed(1)
                    records.refundExplain = Number(data)
                    that.$forceUpdate()
                  }
                  const onChange = (data) => {
                    records.refundExplain = data

                    that.$forceUpdate()
                  }
                  if (records.refundType == '1') {
                    return (
                      <a-select
                        onChange={onChange}
                        style={{ width: '100%' }}
                        placeholder="扣款金额/系数"
                        defaultValue={text}
                        getPopupContainer={() => document.querySelector('.detail-form-group')}
                      >
                        {ticketRefundRatioList.map((e) => {
                          return <a-select-option value={e.value}>{e.name}</a-select-option>
                        })}
                      </a-select>
                    )
                  }
                  return (
                    <a-input
                      disabled={records.refundType == 2}
                      type="number"
                      placeholder="扣款金额/系数"
                      onChange={onInput}
                      value={text}
                    />
                  )
                },
              },
              {
                dataIndex: 'a10',
                align: 'center',
                width: '12%',
                title: '操作',
                unShowEditBtn: true,
                tableType: 'editDelete',
                onDel: (text, records, index) => {
                  if (that.detail.refundList.length > 1) {
                    that.detail.refundList.splice(index, 1)
                  } else {
                    this.$message.error('退款规则最少保留一条规则！')
                  }
                  this.$forceUpdate()
                },
              },
            ],
            dataSource: that.detail.refundList,
          },
        ].filter((e) => e.display),
      }
    },
    getFormTicket() {
      if (this.detail.comboType !== '1') return []
      console.log(this.detail.refundExplain, 'this.detail.refundExplain')
      return [
        {
          title: '退款设置',
          type: 'cardForm',
          data: [
            {
              display: true,
              name: '退款方式',
              type: 'radioButton',
              key: 'refundExplain',
              cols: 12,
              typeData: [
                {
                  name: '随时退',
                  value: '0',
                },
                {
                  name: '不支持退款',
                  value: '1',
                },
                {
                  name: '有效期内可退',
                  value: '2',
                },
              ],
              rules: [
                {
                  required: true,
                },
              ],
            },
            {
              display: true,
              name: '自定义协议',
              type: 'textArea',
              cols: 12,
              key: 'refundAgreement',
              maxLength: 1000,
              minHeight: '100px',
              showCount: true,
            },
          ].filter((e) => e.display),
        },
        this.detail.refundExplain && this.detail.refundExplain !== '1' && this.getFormRules(),
        {
          title: '使用说明',
          type: 'cardForm',
          data: [
            {
              display: true,
              name: '入园设置',
              type: 'radioButton',
              key: 'changeTicket',
              cols: 12,
              typeData: [
                {
                  name: '无需换票',
                  value: '0',
                },
                {
                  name: '换票使用',
                  value: '1',
                },
              ],
            },
            {
              display: this.detail.changeTicket == 1,
              name: '换票地址',
              type: 'input',
              key: 'changeTicketAddress',
              cols: 12,
            },
            {
              display: true,
              name: '说明',
              type: 'textArea',
              key: 'changeTicketRemark',
              cols: 12,
              maxLength: 1000,
              minHeight: '100px',
              showCount: true,
            },
          ].filter((e) => e.display),
        },
        {
          title: '费用说明',
          type: 'cardForm',
          data: [
            {
              name: '费用包含',
              type: 'input',
              key: 'expenseContain',
              cols: 12,
              maxLength: 100,
              showCount: true,
            },
          ],
        },
      ]
    },
    getFormRules() {
      let that = this
      return {
        title: '退款规则',
        type: 'cardForm',
        data: [
          {
            display: this.detail.refundExplain == '0',
            type: 'radioButton',
            key: 'refundWay',
            style: {
              paddingLeft: '20px',
            },
            cols: 24,
            typeData: [
              {
                name: '手动退',
                value: '0',
              },
              {
                name: '过期自动退',
                value: '1',
              },
            ],
          },
          {
            display:
              that.detail.refundExplain == '2' || (that.detail.refundExplain == '0' && that.detail.refundWay == '0'),
            cols: 24,
            type: 'table',
            showFootButton: true,
            showRowSelect: false,
            showPagination: false,
            // table新增按钮回调
            onAddData: () => {
              return {
                validDay: '1',
                validDate: '6',
                refundType: '0',
                refundExplain: 0,
              }
            },
            columns: [
              {
                dataIndex: 'validDay',
                align: 'left',
                width: '22%',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>有效日期
                  </div>
                ),
                customRender: function (text, records, index) {
                  const onChange = (data) => {
                    records.validDay = data
                  }
                  return (
                    <a-select
                      onChange={onChange}
                      style={{ width: '100%' }}
                      placeholder="有效日期"
                      defaultValue={text}
                      getPopupContainer={() => document.querySelector('.detail-form-group')}
                    >
                      {that.detail.refundExplain == '0' &&
                        daysBeforeList.map((e) => {
                          return <a-select-option value={e.value}>{e.name}</a-select-option>
                        })}
                      {that.detail.refundExplain !== '0' &&
                        daysAfterList.map((e) => {
                          return <a-select-option value={e.value}>{e.name}</a-select-option>
                        })}
                    </a-select>
                  )
                },
              },
              {
                dataIndex: 'validDate',
                align: 'left',
                width: '22%',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>有效时间
                  </div>
                ),
                customRender: function (text, records, index) {
                  const onChange = (data) => {
                    records.validDate = data
                  }
                  return (
                    <a-select
                      onChange={onChange}
                      style={{ width: '100%' }}
                      placeholder="有效时间"
                      defaultValue={text}
                      getPopupContainer={() => document.querySelector('.detail-form-group')}
                    >
                      {timeAfterList.map((e) => {
                        return <a-select-option value={e.value}>{e.name}</a-select-option>
                      })}
                    </a-select>
                  )
                },
              },
              {
                dataIndex: 'refundType',
                align: 'left',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>退款类别
                  </div>
                ),
                width: '22%',
                customRender: function (text, records, index) {
                  const onChange = (data) => {
                    records.refundType = data
                    records.refundExplain = 0
                    that.$forceUpdate()
                  }
                  return (
                    <a-select
                      onChange={onChange}
                      style={{ width: '100%' }}
                      getPopupContainer={() => document.querySelector('.detail-form-group')}
                      placeholder="退款类别"
                      defaultValue={text}
                    >
                      {ticketRefundType.map((e) => {
                        return <a-select-option value={e.value}>{e.name}</a-select-option>
                      })}
                    </a-select>
                  )
                },
              },
              {
                dataIndex: 'refundExplain',
                align: 'left',
                width: '22%',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>扣款金额/系数
                  </div>
                ),
                customRender: function (text, records, index) {
                  const onInput = (data) => {
                    records.refundExplain = data.target.value && Number(data.target.value)
                  }
                  const onChange = (data) => {
                    records.refundExplain = data

                    that.$forceUpdate()
                  }
                  if (records.refundType == '1') {
                    return (
                      <a-select
                        onChange={onChange}
                        style={{ width: '100%' }}
                        placeholder="扣款金额/系数"
                        defaultValue={text}
                        getPopupContainer={() => document.querySelector('.detail-form-group')}
                      >
                        {ticketRefundRatioList.map((e) => {
                          return <a-select-option value={e.value}>{e.name}</a-select-option>
                        })}
                      </a-select>
                    )
                  }
                  return <a-input type="number" placeholder="扣款金额/系数" onChange={onInput} value={text} />
                },
              },
              {
                dataIndex: 'a10',
                align: 'center',
                width: '12%',
                title: '操作',
                unShowEditBtn: true,
                tableType: 'editDelete',
                onDel: (text, records, index) => {
                  if (that.detail.refundList.length > 1) {
                    that.detail.refundList.splice(index, 1)
                  } else {
                    this.$message.error('退款规则最少保留一条规则！')
                  }
                },
              },
            ],
            dataSource: that.detail.refundList,
          },
        ].filter((e) => e.display),
      }
    },
    getLabelForm(index) {
      return [
        {
          name: '标签名称',
          type: 'input',
          key: 'labelName',
        },
        {
          name: '标签类型',
          type: 'radioButton',
          key: 'publicLabel',
          defaultValue: '1',
          typeData: [
            // {
            //   name: '店铺私有',
            //   value: '2',
            // },
            {
              name: '公用标签库',
              value: '1',
            },
          ],
        },
      ].filter((e) => e)
    },
    getLabelGroup() {
      return {
        type: 'row',
        children: (this.labelGroup || []).map((e, i) => {
          return (
            <CardIconLabelLocal
              data={this.getLabelForm(i)}
              title={e.labelTypeName}
              pubBool={false}
              cols={24}
              style={{
                marginBottom: '10px',
                minHeight: '200px',
              }}
              icons={e.list}
            />
          )
        }),
      }
    },
    verificationPackage(data) {
      // if (data.length > 6) {
      //   this.$message.warning('套餐组合最多6个商品')
      //   return false
      // }
      const hotelLength = data.filter((e) => e.businessType === '2').length
      if (this.detail.comboType === '1' && hotelLength > 0) {
        this.$message.warning('"景点+景点"类型套餐不支持有酒店类型商品')
        return false
      }

      if (hotelLength > 1) {
        this.$message.warning('套餐只支持一个酒店类型商品')
        return false
      }

      if (this.detail.comboType === '0' && data.length === 6 && hotelLength !== 1) {
        this.$message.warning('"酒店+景点"类型套餐6个商品必须存在一个酒店类型商品')
        return false
      }

      return true
    },
    showCommodity() {
      apiTool.showModal({
        width: '950px',
        title: '添加商品',
        view: Commodity,
        viewProps: {
          // businessIdArr: this.businessIdArr,
        },
        success: ({ data, setHidden }) => {
          // const find = this.detail.details.find((e) => e.businessId == data.businessId)
          // if (find) {
          //   message.error('请勿重复添加商品')
          //   return
          // }
          let allData = [...this.detail.details, data]
          if (!this.verificationPackage(allData)) return
          this.detail.details = allData.map((e, i) => {
            if (!e.productList) {
              this.storeSearchFarmProductCombo(e).then((res) => {
                e.productList = res || []
                this.$forceUpdate()
              })
            }
            return e
          })
          this.$message.success('已添加列表')
          // setHidden()
          this.$forceUpdate()
        },
        foot: () => [],
      })
    },

    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          display: true,
          onClick: (data) => {
            if (this.detail.preSellFlag === '0') {
              data.hxExpireType = '5'
            }
            if (data.hxExpireType == 1 && data.hxAfterDay == 0) {
              this.$message.warning('必须在购买后内使用的天数不能为0')
              return
            }

            if (data.details.length < 2) {
              this.$message.warning('套餐至少由两个商品组合')
              return
            }

            if (!this.verificationPackage(data.details)) return
            const hotelLength = data.details.filter((e) => e.businessType === '2').length
            if (data.comboType === '0' && hotelLength < 1) {
              this.$message.warning('"酒店+景点"类型套餐商品必须存在一个酒店类型商品')
              return
            }

            // if (data.details.filter((e) => !e.salePriceTotal).length) {
            //   this.$message.warning('请完善套餐商品列表')
            //   return
            // }

            if (!data.refundExplain && this.detail.preSellFlag === '1') {
              this.$message.warning('请选择退款设置！')
              return
            }

            if (
              data.refundExplain == 2 &&
              (this.detail?.refundList || []).length < 1 &&
              this.detail.preSellFlag === '1'
            ) {
              this.$message.warning('退款规则最少保留一条规则！')
              return
            }
            if (data.freeRefundFlag != null) {
              data.freeRefundFlag = data.freeRefundFlag[0]
            }
            let newrefundList = []
            if (this.detail.comboType == '0') {
              newrefundList = (this.detail?.refundList || []).map((e) => {
                return {
                  ...e,
                  validDayName: hotelDaysAfterList.find(({ value }) => value === e.validDay).name,
                  validDateName: timeAfterList.find(({ value }) => value === e.validDate).name,
                  refundExplain: e.refundExplain ? e.refundExplain : 0,
                }
              })
            } else {
              newrefundList = this.detail.refundList.map((e) => {
                return {
                  ...e,
                  validDayName:
                    this.detail.refundExplain == '0'
                      ? daysBeforeList.find(({ value }) => value === e.validDay).name
                      : daysAfterList.find(({ value }) => value === e.validDay).name,
                  validDateName: timeAfterList.find(({ value }) => value === e.validDate).name,
                  refundExplain: e.refundExplain ? e.refundExplain : 0,
                }
              })
            }
            let arr = []
            this.labelGroup.forEach((item) =>
              item.list.forEach((e) => {
                e['labelTypeCode'] = item.labelTypeCode
                e['labelTypeId'] = item.id
                e['labelTypeName'] = item.labelTypeName
              })
            )
            this.labelGroup.map((e) => arr.push(...e.list))

            let upDown = data.id ? data.upDown : '0'
            let obj = Object.assign({
              ...data,
              upDown,
              realNameFlag: data.realNameFlag && data.realNameFlag.length > 0 ? data.realNameFlag.join(',') : '0',
              realNameInput: data.realNameInput && data.realNameInput.length > 0 ? data.realNameInput.join(',') : '',
              ticketGiveSetting:
                data.ticketGiveSetting && data.ticketGiveSetting.length > 0 ? data.ticketGiveSetting.join(',') : '',
              linkShopId: data.details[0].linkShopId,
              refundList: newrefundList,
              farmLabelDataDTOS: arr.map((item) => ({
                ...item,
                czState: item.czState ? 1 : 0,
              })),
              preSellFlag: this.detail.preSellFlag == '1' ? '0' : '1',
            })
            api.command[data.id ? 'edit' : 'create']
              .call(this, {
                url: data.id ? '/goods/farmProductCombo/update' : '/goods/farmProductCombo/save',
                params: obj,
                isPost: false,
              })
              .then((result) => {
                setTimeout(() => {
                  this.$router.back()
                }, 500)
              })
          },
        },
      ].filter((e) => e.display)
      const right = [
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.back(),
          display: true,
        },
        {
          name: ` ${this.detail.upDown == 0 ? '下架' : '上架'} `,
          type: 'default',
          onClick: () => {
            getAction(`/goods/farmProductCombo/${this.detail.upDown == 0 ? 'down' : 'up'}?id=${this.detail.id}`).then(
              (e) => {
                if (e.code == 200) {
                  this.$message.success(`${this.detail.upDown == 0 ? '下架成功' : '上架成功'}`)
                  setTimeout(() => {
                    this.$router.back()
                  }, 500)
                } else {
                  this.$message.error(e.msg)
                }
              }
            )
          },
          display: this.detail.id,
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/goods/farmProductCombo?id=${records.id}`,
              isPost: false,
            }),
              setTimeout(() => {
                this.$router.back()
              }, 500)
          },
          display: this.detail.id,
        },
      ].filter((e) => e.display)
      return {
        left,
        right,
      }
    },
    getForm6() {
      return {
        title: '门票设置',
        type: 'cardForm',
        data: [
          {
            name: '核销模式',
            type: 'radioButton',
            key: 'hxMode',
            cols: 12,
            display: true,
            onChange: (value) => {
              if (value == '1') {
                this.detail.qrShowMode = '0'
              }
            },
            typeData: [
              {
                name: '一码一人',
                value: '0',
              },
              {
                name: '一码多人',
                value: '1',
              },
            ],
          },
          {
            name: '门票码展示',
            type: 'radioButton',
            key: 'qrShowMode',
            cols: 12,
            display: true,
            // disabled: this.detail.hxMode == '1',
            typeData: [
              {
                name: '单码',
                value: '0',
              },
              {
                name: '多码',
                value: '1',
              },
            ],
          },
          {
            display: this.detail.comboType == 1,
            name: '码类型',
            type: 'radioButton',
            key: 'codeType',
            cols: 4,
            typeData: [
              {
                name: '静态码',
                value: '0',
              },
              {
                name: '动态码',
                value: '1',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            display: this.detail.codeType == '1',
            name: '刷新频率',
            type: 'input',
            key: 'flushTime',
            cols: 4,
            desc: '秒',
            props: {
              min: 0,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
        ].filter((d) => d.display),
      }
    },
    getFormTk() {
      let that = this
      return {
        title: '订单退款确认方式',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '退款确认方式',
            type: 'radioButton',
            key: 'refundType',
            cols: 12,
            typeData: [
              {
                name: '人工审核退款',
                value: '0',
              },
              {
                name: '自动处理退款',
                value: '1',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
        ].filter((e) => e.display),
      }
    },
    renderTab() {
      return [
        this.getForm1(),
        this.getFormUp(),
        this.getForm2(),
        this.getForm3(),
        this.getFormXssz(),
        this.detail.comboType === '0' && this.detail.preSellFlag === '1' && this.getFormHotel(),
        ...this.getFormTicket(),
        this.detail.preSellFlag === '0' && this.getFormPreSellFlag(),
        this.detail.preSellFlag === '0' && this.getFormPreSellFlag2(),
        this.getForm6(),
        this.getFormTk(),
        this.getLabelGroup(),
      ].filter((e) => e)
    },
  },
  render() {
    return (
      <div>
        <DetailFormGroup
          foot={this.getFoot()}
          form={this.detail}
          typeData={{
            linkShopName: this.storeData,
            hotelName: this.typeSearchDataHotel,
          }}
          data={this.renderTab()}
        />
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
/deep/ .detail-form-group {
  position: relative;
  height: calc(100vh - 230px);
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.add_commodity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
}
</style>
